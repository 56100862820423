import React from 'react';
import { Stack, Box, Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom'
import {Helmet} from "react-helmet";
import { styled } from '@mui/material/styles';
import Requisitos from './Requisitos';
import Calendario from './Calendario';
import Testimonios from './Testimonios';
// import Image from '../../components/Image';
import FileViewer from '../../components/FileViewer';
import convocatoria2 from '../../components/assets/convocatoria3.jpg';
import convocatoriaPDF from '../../components/assets/convocatoria20253.pdf'
import psicocdmx from '../../components/assets/psicocdmx.pdf'


const B = styled('span')({
  color: '#268c83',
  fontWeight: 'bold',
});

// const Img = styled('img')(({theme}) => ({
//   maxWidth: '100%',
//   maxHeight: 140,
//   objectFit: 'contain',
//   [theme.breakpoints.down('md')]: {
//     width: '50%',
//   },
// }));

const Video = styled('video')({
  maxWidth: '100%',
  maxHeight: `400px`,
});

const buttons = [
  {
    id: 1,
    label: "Convocatoria",
    src: convocatoriaPDF,
    type: 'PDF',
  },
  {
    id: 2,
    label: "1. Resultados Pruebas Psicométricas",
    src: psicocdmx,
    variant: 'contained',
    color: 'secondary',
    type: 'PDF',
    date: 'Tue Dec 10 2024 08:59:00 GMT-0600 (Central Standard Time)'
    // date: 'Mon Jan 22 2024 12:36:44 GMT-0600 (Central Standard Time)'
  },
  // {
  //   id: 3,
  //   label: "2. Resultados de Candidatas y Candidatos Finales",
  //   src: resultadosFinales,
  //   variant: 'contained',
  //   color: 'secondary',
  //   type: 'PDF',
  //   date: 'Tue Feb 06 2024 08:59:00 GMT-0600 (Central Standard Time)'
  // },
];

export default function LemMs() {
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const currentDate = new Date();
  const { pathname, hash, key } = useLocation();

  const handleOpenFile = (button) => {
    setSelectedFile(button);
    setOpen(true);
  };

    React.useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          if (hash === '#documento2') {
            setSelectedFile(buttons[1]);
            setOpen(true);
          }
          if (hash === '#documento3') {
            setSelectedFile(buttons[2]);
            setOpen(true);
          }
          if (hash === '#documento4') {
            setSelectedFile(buttons[3]);
            setOpen(true);
          }
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);


  const handleGoToApplication = () => {
    // let now = new Date();
    // if (dayjs(now) > dayjs('Mon Jan 10 2022 00:00:00 GMT-0600 (Central Standard Time)')) {
      window.open('https://convocatoriacdmx.jovenesqueimpactan.org/apply/94', '_blank');
    // } else {
    //   alert("El Registro abre el 10 de Enero, 2022");
    // }
  };

  return (
    <Stack
      alignItems="center"
      sx={{
        flexGrow: 1,
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Helmet>
          <title>Convocatoria CDMX - Jóvenes que Impactan</title>
          <meta name="description" content="Invitamos a jóvenes mexicanos en el programa Jóvenes que Impactan" />
      </Helmet>
      <Stack
        sx={{
          backgroundImage: `url(${convocatoria2})`,
          // color: 'white',
          height: {xs: 200, md: 400},
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: '50% 55%',
        }}
      />
      <Stack sx={{ py: 5, px: '5%', maxWidth: '1200px', width: '100%', boxSizing: 'border-box' }}>
        <Stack direction="row" justifyContent="center">
          <Typography variant="h5">Convocatoria Ciudad de México</Typography>
        </Stack>
        <Stack spacing={2} mb={2}>
          <Stack direction="row" justifyContent="space-around" sx={{ mt: 2}}>
            <Box sx={{ textAlign: 'justify' }}>
              Invitamos a jóvenes mexicanos a participar en el programa <b>Jóvenes que Impactan</b>, 
              el cual, con apoyo de Nacional Monte de Piedad y Fundación Coppel, 
              integra una capacitación híbrida en habilidades interpersonales y técnicas primordiales para la inserción laboral. 
            </Box>
          </Stack>
          <Stack sx={{ mt: 2}}>
            <Typography variant="h5" mt={4}>¿De qué se trata?</Typography>
            <Box sx={{ textAlign: 'justify', mt: 2 }}>
            El programa tiene una duración total de 7 meses, divididos de la siguiente manera:
            </Box>
            <Box sx={{ textAlign: 'justify', mt: 2 }}>
            ● Curso on-line de 4 meses de las habilidades interpersonales más demandadas por 
            las empresas en la actualidad. Cada módulo tiene duración de 1 mes, donde se 
            capacitará a la beneficiaria o el beneficiario a través de plataformas en línea con 
            videos, actividades y tareas, las cuales serán evaluadas de manera mensual. 
            </Box>
            <Box sx={{ textAlign: 'justify', mt: 2 }}>
            ● Acompañamiento personalizado para la inserción laboral durante todos los meses. 
            </Box>
          </Stack>
        </Stack>
        {/* <Video id="video_MS" src={"https://s3.amazonaws.com/lideresenmovimiento.s3/testimoniales_ms.mp4"} controls autoPlay playsInline muted loop /> */}
        <Requisitos />
        <Calendario />
        <Typography variant="h5" mt={4}>Convocatoria</Typography>
        {buttons.map((button) => (
          <Button
            id={`documento${button.id}`}
            key={`file${button.id}`}
            sx={{
              mt: 2,
              ...(button.date && dayjs(button.date) > dayjs(currentDate) && {visibility: 'hidden'})
            }}
            color={button.color || "primary"}
            variant={button.variant || "outlined"}
            onClick={() => handleOpenFile(button)}
          >
            {button.label}
          </Button>
        ))}
        <Button sx={{ mt: 2 }} variant="contained" onClick={handleGoToApplication}>Regístrate aquí</Button>
        {open && (
          <FileViewer
            open={open}
            setOpen={setOpen}
            src={selectedFile.src}
            label={selectedFile.label}
            type={selectedFile.type}
          />
        )}
        <Testimonios />
      </Stack>
    </Stack>
  );
}
