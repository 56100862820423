import './App.css';
import React from 'react';
import dayjs from 'dayjs';
import routes from './routers/routes';
import Router from './routers';
import Menu from './components/Menu';
import FileViewer from './components/FileViewer';
import Alert from '@mui/material/Alert';
import Footer from './components/Footer';
import { useLocation } from 'react-router-dom';
import {Stack, Box} from '@mui/material';
import { useNavigate } from "react-router-dom";
// import psicocdmx from './components/assets/psicocdmx.pdf'
// import psicoqueretaro from './components/assets/psicoqueretaro.pdf'
// import psicopuebla from './components/assets/psicopuebla.pdf'

// const publishDate = 'Tue Nov 15 2022 08:59:00 GMT-0600 (Central Standard Time)';
// const publishDate = 'Wed Feb 15 2023 08:59:00 GMT-0600 (Central Standard Time)';
const publishDate = 'Tue Dec 10 2024 08:59:00 GMT-0600 (Central Standard Time)';
// const publishDate = 'Tue Feb 06 2024 08:59:00 GMT-0600 (Central Standard Time)';
// const publishDate2 = 'Tue Nov 22 2022 08:59:00 GMT-0600 (Central Standard Time)';
// const publishDate3 = 'Tue Feb 28 2023 08:59:00 GMT-0600 (Central Standard Time)'
// const publishDateDisney = 'Wed Nov 13 2024 08:59:00 GMT-0600 (Central Standard Time)';

const fileItems = [
  // {
  //   id: 1,
  //   hash: '#documento1',
  //   label: "Candidatos Pruebas Psicométricas - CDMX",
  //   src: psicocdmx,
  //   type: 'PDF',
  // },
  // {
  //   id: 2,
  //   hash: '#documento2',
  //   label: "Candidatos Pruebas Psicométricas - Querétaro",
  //   src: psicoqueretaro,
  //   type: 'PDF',
  // },
  // {
  //   id: 3,
  //   hash: '#documento3',
  //   label: "Candidatos Pruebas Psicométricas - Puebla",
  //   src: psicopuebla,
  //   type: 'PDF',
  // },
]

function App() {
  const currentDate = new Date();
  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openFile, setOpenFile] = React.useState(false);
  const { pathname, hash, key } = useLocation();
  const [selectedFile, setSelectedFile] = React.useState(null);
  
  // {open && dayjs(currentDate) > dayjs(publishDate2) && 
  //   <Alert variant="filled" severity="info" onClose={() => setOpen(false)}>
  //     <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
  //       <Box sx={{ fontWeight: 'bold', mr: 1/2 }}>Convocatoria León</Box>
  //       <Box> - Ya está publicada la lista de los candidatos finales. Puedes verla </Box>
  //       <Box sx={{ ml: 1/2, cursor: 'pointer', color: 'aquamarine', textDecoration: 'underline' }} onClick={() => navigate(`${routes.leon}#documento3`)}>aquí</Box>
  //     </Stack>
  //   </Alert>
  // }
  
  React.useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    else {
      setTimeout(() => {
        // if (hash === '#documentodisney') {
        //   setSelectedFile(fileItems[0]);
        //   setOpenFile(true);
        // }
        // if (hash === '#documento3') {
        //   setSelectedFile(buttons[2]);
        //   setOpen(true);
        // }
        // if (hash === '#documento4') {
        //   setSelectedFile(buttons[3]);
        //   setOpen(true);
          // }
      }, 0);
    }
  }, [pathname, hash, key]);


  const handleDisneyClick = () => {
    setSelectedFile(fileItems[0]);
    setOpenFile(true);
  }

  return (
    <div className="App">
      {dayjs(currentDate) > dayjs(publishDate) && 
        <Alert variant="filled" severity="info" onClose={() => setOpen(false)}>
          <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
            <Box sx={{ fontWeight: 'bold', mr: 1/2 }}>Convocatoria CDMX</Box>
            <Box> - Ya está publicada la lista de los candidatos selecionados para la fase de pruebas psicométricas. Puedes verla </Box>
            {/* <Box> - Ya está publicada la lista de los candidatos finales. Puedes verla </Box> */}
            <Box sx={{ ml: 1/2, cursor: 'pointer', color: 'aquamarine', textDecoration: 'underline' }} onClick={() => navigate(`${routes.cdmx}#documento2`)}>aquí</Box>
          </Stack>
        </Alert>
      }
      {dayjs(currentDate) > dayjs(publishDate) && 
        <Alert variant="filled" severity="info" onClose={() => setOpen(false)}>
          <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
            <Box sx={{ fontWeight: 'bold', mr: 1/2 }}>Convocatoria Querétaro</Box>
            <Box> - Ya está publicada la lista de los candidatos selecionados para la fase de pruebas psicométricas. Puedes verla </Box>
            {/* <Box> - Ya está publicada la lista de los candidatos finales. Puedes verla </Box> */}
            <Box sx={{ ml: 1/2, cursor: 'pointer', color: 'aquamarine', textDecoration: 'underline' }} onClick={() => navigate(`${routes.queretaro}#documento2`)}>aquí</Box>
          </Stack>
        </Alert>
      }
      {dayjs(currentDate) > dayjs(publishDate) && 
        <Alert variant="filled" severity="info" onClose={() => setOpen(false)}>
          <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
            <Box sx={{ fontWeight: 'bold', mr: 1/2 }}>Convocatoria Puebla</Box>
            <Box> - Ya está publicada la lista de los candidatos selecionados para la fase de pruebas psicométricas. Puedes verla </Box>
            {/* <Box> - Ya está publicada la lista de los candidatos finales. Puedes verla </Box> */}
            <Box sx={{ ml: 1/2, cursor: 'pointer', color: 'aquamarine', textDecoration: 'underline' }} onClick={() => navigate(`${routes.puebla}#documento2`)}>aquí</Box>
          </Stack>
        </Alert>
      }
      {/* {open && dayjs(currentDate) > dayjs(publishDate) && 
        <Alert variant="filled" severity="info" onClose={() => setOpen(false)}>
          <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
            <Box sx={{ fontWeight: 'bold', mr: 1/2 }}>Convocatoria Puebla</Box>
            <Box> - Ya está publicada la lista de los candidatos selecionados para la fase de pruebas psicométricas. Puedes verla </Box>
            <Box> - Ya está publicada la lista de los candidatos finales. Puedes verla </Box>
            <Box sx={{ ml: 1/2, cursor: 'pointer', color: 'aquamarine', textDecoration: 'underline' }} onClick={() => navigate(`${routes.puebla}#documento3`)}>aquí</Box>
          </Stack>
        </Alert>
      } */}
      {/* {publishDateDisney && dayjs(currentDate) > dayjs(publishDateDisney) && 
        <Alert variant="filled" severity="info" onClose={() => setOpen(false)}>
          <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
            <Box sx={{ fontWeight: 'bold', mr: 1/2 }}>Convocatoria Disney</Box>
            <Box> - Ya está publicada la lista de candidatos finales. Puedes verla </Box>
            <Box> - Ya está publicada la lista de los candidatos finales. Puedes verla </Box>
            <Box sx={{ ml: 1/2, cursor: 'pointer', color: 'aquamarine', textDecoration: 'underline' }} onClick={handleDisneyClick}>aquí</Box>
          </Stack>
        </Alert>
      } */}
      {/* {open && dayjs(currentDate) > dayjs(publishDate3) && 
        <Alert variant="filled" severity="info" onClose={() => setOpen(false)}>
          <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
            <Box sx={{ fontWeight: 'bold', mr: 1/2 }}>Convocatoria Barrio Adentro</Box>
            <Box> - Ya está publicada la lista de los candidatos finales. Puedes verla </Box>
            <Box sx={{ ml: 1/2, cursor: 'pointer', color: 'aquamarine', textDecoration: 'underline' }} onClick={() => navigate(`${routes.barrioadentro}#documento3`)}>aquí</Box>
          </Stack>
        </Alert>
      } */}
      <Menu />
      <Stack sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '18px',
      }}>

        <Router />
      </Stack>
      {openFile && (
          <FileViewer
            open={openFile}
            setOpen={setOpenFile}
            src={selectedFile.src}
            label={selectedFile.label}
            type={selectedFile.type}
          />
      )}
      <Footer />
    </div>
  );
}

export default App;
