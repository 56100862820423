import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../containers/Home';
import ConvocatoriaCDMX from '../containers/ConvocatoriaCDMX2025/index';
import ConvocatoriaQueretaro from '../containers/ConvocatoriaQueretaro2025/index';
import ConvocatoriaPuebla from '../containers/ConvocatoriaPuebla2025/index';
import routes from './routes';

export default function Router(props) {

  return (
    <Routes>
      <Route exact path={routes.root} element={<Home />} />
      <Route exact path={routes.cdmx} element={<ConvocatoriaCDMX />} />
      <Route exact path={routes.queretaro} element={<ConvocatoriaQueretaro />} />
      <Route exact path={routes.puebla} element={<ConvocatoriaPuebla />} />
    </Routes>
  );
}
